import { render, staticRenderFns } from "./addOrEdit.vue?vue&type=template&id=539e0ada&scoped=true"
import script from "./addOrEdit.vue?vue&type=script&lang=js"
export * from "./addOrEdit.vue?vue&type=script&lang=js"
import style0 from "./addOrEdit.vue?vue&type=style&index=0&id=539e0ada&lang=scss&scoped=true"
import style1 from "./addOrEdit.vue?vue&type=style&index=1&id=539e0ada&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539e0ada",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('539e0ada')) {
      api.createRecord('539e0ada', component.options)
    } else {
      api.reload('539e0ada', component.options)
    }
    module.hot.accept("./addOrEdit.vue?vue&type=template&id=539e0ada&scoped=true", function () {
      api.rerender('539e0ada', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/order/orderConfig/model/addOrEdit.vue"
export default component.exports