<template>
	<mainBox :noPadding='true' :mClass='"noShadow"'>
		<div class="warpBox" >
			<div class="b-left">
				<div class="pTitle"><i class="sline" />项目列表</div>
				<div class="itsWarp">
					<div class="data0" v-if="projectsArr.length<1">{{$t('commons.noData')}}</div>
					<ul>
						<li class="itp yHover txEllipsis" v-for="(it,dex) in projectsArr" :key='dex' :class="{ isActive: activeProject == it }" @click="changeActivePro(it)">{{it.projectName}}</li>
					</ul>
				</div>
			</div>
			<div class="pTemplates">
				<el-tabs class='eTabs' v-model="activeTab" type="card"  @tab-remove="closeTempConfig('manual')" >
					<el-tab-pane v-for="(val, key) in tabTypes" v-if='val.isShow' :key="key" :label="val.name" :name="key" :closable='key!=="init"' />
				</el-tabs>
				<div class="txBody" v-if="activeTab=='init'">
					<el-button class='evbtn' type="primary" @click='addTemp()'>新建模版</el-button>
					<div class="tmsWarp">
						<div class="data0" v-if="tempArrs.length<1">{{$t('commons.noData')}}</div>
						<el-row v-else>
					 	 	<el-col style='min-width: 208px;' :span="8" v-for="(it,dex) in tempArrs" :key='dex'>
						 	 	<div class="tmIt" >
									<div class="tm_top" :class='{isEnable:it.configStatus=="DISABLE"}'>
										<div class="txEllipsis">{{it.configName}} <span v-if="it.isDefault==='Y'" class="dftext">[默认]</span></div>
										<span class='s_btn' :class='{disBtn:it.configStatus=="DISABLE"}'>{{it.configStatusName}}</span>
									</div>
									<div class="tm_main">
										<div class="sv_type">服务类型：{{it.serviceTypeName}}</div>
										<div class="tm_des">模板描述：{{it.description}}</div>
										<div class="b_btns">
											<el-button class='txbtn' type="text" @click.stop='tempCopy(it)'>复制</el-button>
											<el-button class='txbtn' type="text" @click.stop='tempEdit(it)'>修改</el-button>
											<el-button v-if='it.configStatus=="DISABLE"' class='txbtn' type="text" @click.stop='tempOpenStatus(it)'> 启用</el-button>
											<el-button v-if='it.configStatus=="ENABLE"' class='txbtn'  type="text" @click.stop='tempSetStatus(it,"DISABLE")'>禁用</el-button>
											<el-button class='txbtn' type="text" :disabled='it.configStatus=="ENABLE"' @click.stop='tempDelete(it)'>删除</el-button>
										</div>
									</div>
									
								</div>
						 	 	
					 	 	</el-col>
						</el-row>
						
						
					</div>
					
				</div>
				<div v-if="isAddTemp">
					<AddOrEdit ref='refAdd' v-show="activeTab=='config'" :proData='activeProject' :servTypeDatas='servTypeArrs' :editId='tempEditId'  @sendOver='submitOver'/>
				</div>
			</div>
		</div>

	</mainBox>
</template>

<script>
	import AddOrEdit from './model/addOrEdit.vue'
	export default {
		components: {AddOrEdit},
		data() {
			return {
				projectsArr: [],
				activeProject: {},//选中的项目数据
				activeTab: 'init',
				tabTypes:{
					init:{name:'工单模版',isShow:true},//代表初始页面
					config:{name:'模板配置',isShow:false},//扩展页面
				},
				servTypeArrs:[],//服务类型  子页面也要用该数据  向下传递
				servNamesObj:{},// 例 "internal_use":"内部使用"
				tempEditId:'',//新增时候为空，编辑时有值
				tempArrs:[
//					{
//						configName:'默认模版',
//						configStatus:'ENABLE',//ENABLE启用 ；Disable禁用
//						configStatusName:'启用',
//						serviceType:'no_charge',//对应后台接口数据
//						serviceTypeName:'内部使用',
//						description:'模版描述：',
//					}
				
				
				],
				isAddTemp:true,
			};
		},
		computed: {},
		watch: {},
		async created() {
			await this.initServiceArrs();
			this.initProjectArrs();
		},
		mounted() {},
		methods: {
			
			async initServiceArrs() {
				//获取服务类型
				let res = await this.ApiHttp('/lookup/lookup/item/find',{
					isloading:false,
					classifyCode:'ORDER_SERVICE_TYPE',
				});
				if(res){
					res.lookupItemList.map(it=>{
						it.name=it.itemName;
						it.code=it.itemCode;
						this.servNamesObj[it.code]=it.itemName;
					})
					this.servTypeArrs=res.lookupItemList;//服务类型数据
				}
			},
			addTemp(){
				this.isAddTemp=false;
				setTimeout(()=>{
					//这里要通过isAddTemp的值  重新加载 刷新  数据恢复到最初始值
					this.isAddTemp=true;
					this.openConfig();
				},100)
			},
			openConfig(eid){
				this.tabTypes['config'].isShow=true;
				this.activeTab='config';
				if(eid){
					this.tempEditId=eid;
					this.$refs.refAdd.setStepsVal();
				}else{
					this.tempEditId='';
				}
			},
			closeTempConfig(isManual) {
				if(!this.tempEditId) this.$refs.refAdd.resetFormItVals();//清空子组件的值
				if(isManual){
					this.$confirm('关闭页面，所填入的内容将会被清除，确认关闭吗？', '提醒').then(async () => {
						this.tabTypes['config'].isShow=false;
						this.activeTab='init';
						this.tempEditId='';
						this.$refs.refAdd.resetFormItVals();
					})
				}else{
					this.tabTypes['config'].isShow=false;
					this.activeTab='init';
				}
			},
			
			changeActivePro(pro){
				this.activeProject=pro;
				this.closeTempConfig();
				this.initComplist();
			},
			async initComplist(){
				if(!this.activeProject.projectId) return;
				let res=await this.ApiHttp('/order/tenant/orderConfig/getConfigList?projectId='+this.activeProject.projectId);
				res.map(it=>{
					it.serviceTypeName=this.servNamesObj[it.serviceType];
					it.configStatusName=it.configStatus=="ENABLE"?'已启用':'已禁用';
					if(!it.description) it.description='无';
				})
				this.tempArrs=res;
			},
			
			async initProjectArrs() {
				//获取项目列表
				let res = await this.ApiHttp('/base/tenant/project/list')
				if(res&&res.length>0){
					this.projectsArr = res;
					this.changeActivePro(res[0]);
				}
			},
			async tempCopy(itData){
				//复制
				this.$confirm('是否复制 [ '+itData.configName+' ] 模版', '提示', {type: 'warning'}).then(async () => {
					let params={
					"orderConfigId":itData.orderConfigId,
				}
				this.ApiHttp('/order/tenant/orderConfig/copyConfig',params,'post').then(res=>{
					this.initComplist();
					this.$message({type:'success',message:'操作成功'});
				});
				})
				
				
				
			},
			submitOver(val){
				this.tempEditId='';
				this.closeTempConfig();
				this.initComplist();
				
			},
			async tempEdit(itData){
				//修改
				this.openConfig(itData.orderConfigId);
			},
			async tempOpenStatus(itData){
				//启用模板
				let params={
						"orderConfigId":itData.orderConfigId,
						"configStatus":'ENABLE',//configStatus：启用：ENABLE 禁用：DISABLE
					}
					this.ApiHttp('/order/tenant/orderConfig/updateConfigStatus',params,'put').then(res=>{
						this.$message({type:'success',message:'操作成功'});
						this.initComplist();
					});
			},
			async tempSetStatus(itData,type){
				//修改模板状态
				this.$confirm('禁用此模板以后,无法使用此模板进行报单', '温馨提示', {type: 'warning'}).then(async () => {
					let params={
						"orderConfigId":itData.orderConfigId,
						"configStatus":type,//configStatus：启用：ENABLE 禁用：DISABLE
					}
					this.ApiHttp('/order/tenant/orderConfig/updateConfigStatus',params,'put').then(res=>{
						this.$message({type:'success',message:'禁用成功'});
						this.initComplist();
					});
				})
			},
			tempDelete(itData){
				//删除
				const h = this.$createElement;
        this.$msgbox({
          title: '温馨提示',
          message: h('p', null, [
            h('span', null, '删除模版不会删除已生成的工单数据， '),
            h('span', { style: 'color: red' }, '但已生成工单无法精准查询！'),
            h('span', null, '确认删除吗？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
            	done();
            	let params={
								"orderConfigId":itData.orderConfigId,
							}
							this.ApiHttp('/order/tenant/orderConfig/updateConfigIsDelete?orderConfigId='+itData.orderConfigId,params,'put').then(res=>{
								this.$message({type:'success',message:'删除成功'});
								this.initComplist();
							});
            } else {
              done();
            }
          }
        })
				
				
//				this.$confirm('删除模版不会删除已生成的工单数据，但已生成工单无法精准查询！', '温馨提示', {type: 'warning'}).then(async () => {
//					
//					let params={
//						"orderConfigId":itData.orderConfigId,
//					}
//					this.ApiHttp('/order/tenant/orderConfig/updateConfigIsDelete?orderConfigId='+itData.orderConfigId,params,'put').then(res=>{
//						this.$message({type:'success',message:'删除成功'});
//						this.initComplist();
//					});
//					
//					
//				})
				
			},

		},
		destroyed() {},
	};
</script>

<style lang="scss" scoped>
	.warpBox {
		height: 100%;
		background: #F1F2F4;
		display: flex;
		.b-left,.pTemplates{
			border-radius: 3px;
			background: #FFFFFF;
			box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
		}
		.b-left {
			width: 15%;
			min-width: 200px;
			margin-right: 18px;
			.pTitle{
				border-bottom:1px solid #EBEEF5;
				margin-bottom: 10px;
			}
		}
		.pTemplates{
			width: 85%;
			max-width: calc( 100% - 200px);
			padding: 20px 12px ;
			.eTabs{
				margin-left: 10px;
			}
		}
	}
	
	.itsWarp {
		height: calc( 100% - 60px);
		overflow-y: auto;
		.itp {
			padding-left: 42px;
			line-height: 40px;
			cursor: pointer;
		}
	}
	
	.data0{
		padding-left: 14px;
		color: #909399;
		font-size: 12px;
	}
	.txBody{
		.evbtn{
			margin: 5px 0 20px 10px;
		}
		.tmsWarp{
			max-height: calc(100vh - 236px);
			overflow-y: auto;
			.tmIt{
				border-radius: 3px;
				border: 1px solid #DCDFE6;
				margin: 8px;
				.tm_top{
					background: #edf6ff;
					padding-left:20px;
					font-weight: 600;
					font-size: 16px;
					border-bottom: 1px solid #DCDFE6;
					line-height: 50px;
					position: relative;
					.txEllipsis{
						max-width: calc(100% - 80px);
					}
					.s_btn{
						position: absolute;
						top: 12px;
						right: 15px;
						width: 56px;
						height: 28px;
						border-radius: 4px;
						padding: 0;
				    line-height: 28px;
  					text-align: center;
						font-size: 12px;
						color: #409EFF;
						background:#ddecfb;;
					}
					.s_btn.disBtn{
						color: #999999;
						background:#e7e7e7;
					}
					
				}
				.tm_main{
					color: #666666;
					padding: 20px;
					.sv_type{
						margin-bottom: 16px;
					}
					.tm_des{
						height: 44px;
						margin-bottom: 20px;
						overflow-y: auto;
				    display: -webkit-box;
				    overflow: hidden;
				    line-height: 20px;
				    -webkit-box-orient: vertical;
				    -webkit-line-clamp: 2;
				    text-overflow: ellipsis;
					}
					.txbtn{
						padding: 0;
						/*margin: ;*/
						margin-left: 20px;
					}
					.txbtn:first-child{
						margin-left: 0;
					}
				}
				.tm_top.isEnable{
					background: #F2F2F2;
				}
				.dftext{
					color: orange;
				}
			}
		}
	}
</style>
