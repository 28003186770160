<template>
  <div class="AddHtml">
    <div class="ySteps">
      <div
        v-for="(it, dex) in stepArrs"
        :key="dex"
        class="it_step"
        :class="{ isPass: nowSteps >= it.val }"
      >
        <div v-if="dex > 0" class="line"></div>
        <div class="round">{{ it.val }}</div>
        {{ it.name }}
      </div>
    </div>

    <div class="configFrom" ref="configFrom">
      <TForm
        v-if="nowSteps < 2"
        ref="htmlFrom"
        :model="pageParams"
        :rules="validator"
        :formlist="formlist"
        label-width="108px"
      >
        <template slot="oTypeArrs" slot-scope="scope">
          <div>
            <tag-bind
              :tagValueList="pageParams[scope.current.keys]"
              tagType
              :notAuto="true"
              :limit="4"
              :maxlength="4"
              :addName="'添加类型'"
            />
          </div>
        </template>
        <template slot="robOrder" slot-scope="scope">
          <div>
            <el-input
              class="w108"
              v-model="pageParams[scope.current.keys]"
              placeholder="请输入"
              oninput="value=value?value.replace(/[^0-9]/g,''):0"
              maxlength="2"
            ></el-input>
            <span>{{ scope.current.tips }}</span>
          </div>
        </template>

        <template slot="workHours" slot-scope="scope">
          <div>
            <div>
              <span>{{ scope.current.tips }}</span> &nbsp;
              <el-input
                class="w108"
                v-model="pageParams[scope.current.keys]"
                placeholder="请输入"
                oninput="value=value?value.replace(/[^0-9]/g,''):0"
                maxlength="3"
              />
              &nbsp; 分钟
            </div>
            <div class="alarmBox">
              <div class="txGray">（说明：按工单级别）</div>
              <el-tabs v-model="pageParams.statusType" type="card">
                <el-tab-pane
                  class="no_border"
                  v-for="(it, dex) in statusArr"
                  :key="dex"
                  :label="it.name"
                  :name="it.val"
                >
                  <div class="itConfigs">
                    <ul>
                      <li>
                        待领取的工单超过
                        <el-input
                          class="w108"
                          v-model="it.datas.unclaimedOvertime"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />
                        分钟未被领取，则视为超时。
                      </li>
                      <li>
                        待执行的工单超过
                        <el-input
                          class="w108"
                          v-model="it.datas.firstExecutedOvertime"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />
                        分钟，还未开始执行视为一次超时，之后每过
                        <el-input
                          class="w108"
                          v-model="it.datas.secondaryExecutedOvertime"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />
                        分钟，视为一次超时。
                      </li>
                      <li>
                        执行中的工单截止要求完成时间还未完成提交视为一次超时，之后每过
                        <el-input
                          class="w108"
                          v-model="it.datas.completedOvertime"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />
                        分钟，视为一次超时。
                      </li>
                      <li>
                        第
                        <el-input
                          class="w108"
                          v-model="it.datas.timeReportDuty"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />次超时，通知岗位负责人。
                      </li>
                      <li>
                        第
                        <el-input
                          class="w108"
                          v-model="it.datas.timeReportDept"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />次超时，通知部门负责人。
                      </li>
                      <li>
                        第
                        <el-input
                          class="w108"
                          v-model="it.datas.timeReportSuperiorDept"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />次超时，通知上级部门负责人， 最多越
                        <el-input
                          class="w108"
                          v-model="it.datas.timeReportSuperiorMax"
                          oninput="value=value?value.replace(/[^0-9]/g,''):0"
                          maxlength="3"
                          placeholder="请输入"
                        />级上报。
                      </li>
                    </ul>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </template>
      </TForm>
      <TForm
        v-else
        ref="htmlFrom"
        :model="examineFrom"
        :rules="validator2"
        :formlist="formlist2"
        label-width="120px"
      ></TForm>

      <div class="f_btns" v-if="nowSteps < 2">
        <el-button type="primary" class="w130" @click="nextStep"
          >下一步</el-button
        >
      </div>
      <div class="f_btns pl120px" v-else>
        <el-button type="primary" class="w130" @click="nowSteps--"
          >上一步</el-button
        >
        <el-button type="primary" class="w130" @click="onSubmit"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TagBind from "@/components/TagBind";
	export default {
		computed: {},
		components: {
			TForm,
			TagBind
		},
		props: {
			proData: { //选中的项目
				type: Object,
				default: () => {
					return {
						companyId: '',
						projectId: '',
					}
				},
			},
			servTypeDatas: {
				type: Array | null,
				default: null,
			},
			editId: {
				//修改或查看时获取详细的id => 传过来的 orderConfigId
				type: String | Number,
				default: '',
			},
		},
		watch: {
			proData: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.initNowHtml();
				}
			},
			editId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.initNowHtml();
				}
			},
			nowSteps: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.$refs.configFrom.scrollTop = 0
					})
				}
			}
		},
		data() {
			return {
				nowSteps: 1,
				stepArrs: [{
						name: '基础配置',
						val: 1
					},
					{
						name: '审核配置',
						val: 2
					}
				],
				pageParams: {}, //基础配置参数
				examineFrom: {}, //审核配置参数
				statusArr: [ //工时设置的状态
					{
						name: '普通',
						val: '0',
						datas: {}
					},
					{
						name: '紧急',
						val: '1',
						datas: {}
					},
					{
						name: '告警',
						val: '2',
						datas: {}
					},
				],
				servTypeArrs: [], //服务类型数据
				applyOptions:[],//申请类型
				departArrs: [], //部门数据
				empListArrs: [], //部门人员数据
				formlist: [{
						name: '模版名称',
						keys: 'configName',
						value: '',
						type: 'input',
						diyClass: 'max260',
						maxlen: 10
					},
					{
						name: '服务类型',
						keys: 'serviceType',
						value: '',
						options: [],
						type: 'select',
						diyClass: 'max260'
					},
					{
						name: '模版备注',
						keys: 'description',
						value: '',
						type: 'input',
						diyClass: 'max260',
						maxlen: 80
					},
					{
						name: '申请类型',
						keys: 'applyTypeIdList',
						value: [],
						multiple: true,
						options: [],
						type: 'select',
						diyClass: 'max260'
					},
					{
						name: '默认模板',
						keys: 'isDefault',
						value: 'N',
						type: 'switch',
						tips: '(注意:若开启,智能设备将根据该模板自动生产工单)',
						tipClass: 'tipRed'
					},
					{
						name: '工单类型',
						keys: 'oTypeArrs',
						value: [],
						type: 'slot',
						slotName: 'oTypeArrs',
						maxlen: 80
					},
					{
						name: '默认责任部门',
						keys: 'defDeptId',
						value: '',
						options: [],
						type: 'select',
						diyClass: 'max260'
					},
					{
						name: '工单责任部门',
						keys: 'impDeptArr',
						value: [],
						multiple: true,
						options: [],
						type: 'select',
						diyClass: 'max260'
					},
					{
						name: '工单协助部门',
						keys: 'assistDeptArr',
						value: [],
						multiple: true,
						options: [],
						type: 'select',
						diyClass: 'max260'
					},
					{
						name: '是否现场签到',
						keys: 'isSign',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '若开启，开始作业前，执行人需要现场定位签到'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，则无需现场定位签到，直接开始作业'
							},
						]
					},
					{
						name: '是否签字',
						keys: 'isAutograph',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '若开启，则完成工单时，需签字确认才能提交工单'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，则无需签字确认，直接提交工单'
							},
						]
					},
					{
						name: '抢单上限',
						keys: 'receiveTopLimit',
						value: '',
						type: 'slot',
						slotName: "robOrder",
						tips: '（超过这个数量，用户将不能再领取新的工单）'
					},
					
					{
						name: '工单配置类型',
						keys: 'configType',
						value: '',
						options: [
							{name:'手动报单',code:1},
							{name:'组合工单模板',code:2},
						],
						type: 'select',
						diyClass: 'max260'
					},
					{
						name: '工单内容描述预填写',
						keys: 'problemDesc',
						value: '',
						type: 'textarea',
						diyClass: 'desTxare',
						maxlen:600,
					},
					
					{
						name: '工时设置',
						keys: 'impWorkingHours',
						value: '0',
						type: 'slot',
						slotName: "workHours",
						tips: '工单执行标准工时'
					},
					//					{name:'备  注',   keys:'description',value:'',isMust:true,type:'textarea'},
				],
				formlist2: [{
						name: '报单是否审核',
						keys: 'isReportAudit',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '开启后，则需要选择相应的审批人'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，报单成功后，无需审核，直接自动派单'
							},
						],
						//						isAudit:true,//是否有审核人
						//						checkType:'cascader',//选择审核类型  下拉或者自动超时审核，或者其他[拓展]
						//						multiple:true,//是否多选
						//						auditVal:[],//单选的时候 类型为string
						//						auditkey:'reportAuditType',
						//						auditOptions:[],//审核人选项
						changMethod: this.openAutoOrder,
						relation: ['reportAuditType', 'isReportTimeout'], //关联的字段， 通过该字段去查找例如 报单是否审核 关联  报单自动审核
					},
					{
						name: '工单报单审核',
						keys: 'reportAuditType',
						auditVal: [],
						value: 1,
						type: 'lbRadioCader',
						notags:true,
						selectClass:'torow1',
						isHide: true,
						options: [],
					},
					{
						name: '报单自动审核',
						keys: 'isReportTimeout',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '若开启，请设置自动审核时间'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，则需审核人完成审核后，生成工单'
							},
						],
						isHide: true,
						isAudit: true, //是否有审核人
						checkType: 'autoTime', //选择审核类型  下拉或者自动超时审核 或者其他[拓展]
						inpIsNum: true,
						inpMaxlen: 2,
						auditVal: '0', //单选的时候 类型为string
						auditkey: 'reportTimeoutTime',
					},
					{
						name: '是否修改工单',
						keys: 'isModify',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '若开启，1.则部门负责人可以修改工单信息 </br>2.同时开启报单审核时，则审核人可修改工单信息'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，则不支持修改工单信息'
							},
						],
					},
					{
						name: '关单是否审核',
						keys: 'isCloseAudit',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '开启后，则需要选择相应的审批人'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，关单申请时，无需审核，直接关闭工单'
							},
						],
						changMethod: this.openAutoOrder,
						relation: ['closeAuditType', ], //关联的字段， 通过该字段去查找例如 报单是否审核 关联  报单自动审核
					},
					{
						name: '关单审核',
						keys: 'closeAuditType',
						value: 1,
						type: 'lbRadioCader',
						isHide: true,
						auditVal: [],
						options: [],
					},
					{
						name: '转派是否审核',
						keys: 'isTransferAudit',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '开启后，则需要选择相应的审批人'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，协助申请后，无需审核，直接协助通过'
							},
						],
						changMethod: this.openAutoOrder,
						relation: ['transferAuditType', 'isTransferPersonAudit'],
					},
					{
						name: '工单转派审核',
						keys: 'transferAuditType',
						value: 1,
						type: 'lbRadioCader',
						isHide: true,
						options: [],
						auditVal: [],
						changMethod: this.hideTransferPersonAudit,
					},
					{
						name: '接收人是否审核',
						keys: 'isTransferPersonAudit',
						isHide: true,
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '若开启后，需要接收人再次审核，才能完成转派'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，则无需接收人审核，直接转派通过'
							},
						]
					},
					{
						name: '协助是否审核',
						keys: 'isAssistAudit',
						value: 'N',
						type: 'labelRadio',
						options: [{
								name: '开启',
								val: 'Y',
								tips: '开启后，则需要选择相应的审批人'
							},
							{
								name: '关闭',
								val: 'N',
								tips: '若关闭，协助申请后，无需审核，直接协助通过'
							},
						],
						changMethod: this.openAutoOrder,
						relation: ['assistAuditType', 'isAssistPersonAudit'], //关联的字段， 通过该字段去查找例如 报单是否审核 关联  报单自动审核
					},

        {
          name: "工时设置",
          keys: "impWorkingHours",
          value: "0",
          type: "slot",
          slotName: "workHours",
          tips: "工单执行标准工时",
        },
        //					{name:'备  注',   keys:'description',value:'',isMust:true,type:'textarea'},
      ],
      formlist2: [
        {
          name: "报单是否审核",
          keys: "isReportAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，报单成功后，无需审核，直接自动派单",
            },
          ],
          //						isAudit:true,//是否有审核人
          //						checkType:'cascader',//选择审核类型  下拉或者自动超时审核，或者其他[拓展]
          //						multiple:true,//是否多选
          //						auditVal:[],//单选的时候 类型为string
          //						auditkey:'reportAuditType',
          //						auditOptions:[],//审核人选项
          changMethod: this.openAutoOrder,
          relation: ["reportAuditType", "isReportTimeout"], //关联的字段， 通过该字段去查找例如 报单是否审核 关联  报单自动审核
        },
        {
          name: "工单报单审核",
          keys: "reportAuditType",
          auditVal: [],
          value: 1,
          type: "lbRadioCader",
          notags: true,
          selectClass: "torow1",
          isHide: true,
          options: [],
        },
        {
          name: "报单自动审核",
          keys: "isReportTimeout",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "若开启，请设置自动审核时间",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，则需审核人完成审核后，生成工单",
            },
          ],
          isHide: true,
          isAudit: true, //是否有审核人
          checkType: "autoTime", //选择审核类型  下拉或者自动超时审核 或者其他[拓展]
          inpIsNum: true,
          inpMaxlen: 2,
          auditVal: "0", //单选的时候 类型为string
          auditkey: "reportTimeoutTime",
        },
        {
          name: "是否修改工单",
          keys: "isModify",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "若开启，1.则部门负责人可以修改工单信息 </br>2.同时开启报单审核时，则审核人可修改工单信息",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，则不支持修改工单信息",
            },
          ],
        },
        {
          name: "关单是否审核",
          keys: "isCloseAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，关单申请时，无需审核，直接关闭工单",
            },
          ],
          changMethod: this.openAutoOrder,
          relation: ["closeAuditType"], //关联的字段， 通过该字段去查找例如 报单是否审核 关联  报单自动审核
        },
        {
          name: "关单审核",
          keys: "closeAuditType",
          value: 1,
          type: "lbRadioCader",
          isHide: true,
          auditVal: [],
          options: [],
        },
        {
          name: "转派是否审核",
          keys: "isTransferAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，协助申请后，无需审核，直接协助通过",
            },
          ],
          changMethod: this.openAutoOrder,
          relation: ["transferAuditType", "isTransferPersonAudit"],
        },
        {
          name: "工单转派审核",
          keys: "transferAuditType",
          value: 1,
          type: "lbRadioCader",
          isHide: true,
          options: [],
          auditVal: [],
          changMethod: this.hideTransferPersonAudit,
        },
        {
          name: "接收人是否审核",
          keys: "isTransferPersonAudit",
          isHide: true,
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "若开启后，需要接收人再次审核，才能完成转派",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，则无需接收人审核，直接转派通过",
            },
          ],
        },
        {
          name: "协助是否审核",
          keys: "isAssistAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，协助申请后，无需审核，直接协助通过",
            },
          ],
          changMethod: this.openAutoOrder,
          relation: ["assistAuditType", "isAssistPersonAudit"], //关联的字段， 通过该字段去查找例如 报单是否审核 关联  报单自动审核
        },

        {
          name: "工单协助审核",
          keys: "assistAuditType",
          auditVal: [],
          value: 1,
          type: "lbRadioCader",
          isHide: true,
          options: [],
          changMethod: this.hideAssistPersonAudit,
        },

        {
          name: "协助人是否审核",
          keys: "isAssistPersonAudit",
          value: "N",
          isHide: true,
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "若开启后，需要协助人再次审核，才能完成协助",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，则无需协助人审核，直接协助通过",
            },
          ],
        },
        {
          name: "延时是否审核",
          keys: "isDelayedAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，延时申请后，无需审核，直接延时通过",
            },
          ],
          changMethod: this.openAutoOrder,
          relation: ["delayedAuditType"],
        },
        {
          name: "工单延时审核",
          keys: "delayedAuditType",
          auditVal: [],
          value: 1,
          type: "lbRadioCader",
          isHide: true,
          options: [],
        },
        {
          name: "物料是否审核",
          keys: "isMaterielAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，物料申请后，无需审核，直接领用物料",
            },
          ],
          changMethod: this.openAutoOrder,
          relation: ["materielAuditType"],
        },
        {
          name: "工单物料审核",
          keys: "materielAuditType",
          auditVal: [],
          value: 1,
          type: "lbRadioCader",
          isHide: true,
          options: [],
        },
        {
          name: "完成是否审核",
          keys: "isCompleteAudit",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，执行完成后，无需审核，直接结束工单",
            },
          ],
          changMethod: this.openAutoOrder,
          relation: ["completeAuditType", "isCompleteTimeout"],
        },
        {
          name: "工单完成审核",
          keys: "completeAuditType",
          auditVal: [],
          value: 1,
          type: "lbRadioCader",
          isHide: true,
          options: [],
        },
        {
          name: "完成自动审核",
          keys: "isCompleteTimeout",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "若开启，设置自动审核通过时长",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，则需审核人审核通过后，工单结束",
            },
          ],
          isHide: true,
          isAudit: true,
          multiple: true,
          inpIsNum: true,
          inpMaxlen: 2,
          auditVal: "0",
          auditkey: "completeTimeoutTime",
          checkType: "autoTime",
          auditOptions: [],
        },
        {
          name: "有偿工单入口",
          keys: "canPaid",
          value: "N",
          type: "labelRadio",
          options: [
            {
              name: "开启",
              val: "Y",
              tips: "开启后，则需要选择相应的审批人",
            },
            {
              name: "关闭",
              val: "N",
              tips: "若关闭，则关闭有偿工单服务入口",
            },
          ],
        },
      ],
      auditCheckOptions: {
        //工单报单审核  it 的valkeys为空时  代表不用传  只需要传对应的值就行。
        reportAuditType: [
          {
            code: 1,
            name: "责任部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 2,
            name: "指定人",
            valkeys: "reportApproveUserList",
            optionsType: "emplist",
            childs: [],
          },
          {
            code: 3,
            name: "指定部门主管",
            valkeys: "reportDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
        //关单审核
        closeAuditType: [
          {
            code: 1,
            name: "责任部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 2,
            name: "指定人",
            valkeys: "closeApproveUserList",
            optionsType: "emplist",
            childs: [],
          },
          {
            code: 3,
            name: "指定部门主管",
            valkeys: "closeDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
        //工单转派审核
        transferAuditType: [
          {
            code: 5,
            name: "接收人审核",
            valkeys: "",
            childs: [],
          },
          {
            code: 1,
            name: "责任部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 2,
            name: "接收人部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 3,
            name: "指定人",
            valkeys: "transferDesignatedPersonnel",
            optionsType: "emplist",
            limit1: 1,
            childs: [],
          },
          {
            code: 4,
            name: "指定部门主管",
            valkeys: "transferDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
        //工单协助审核
        assistAuditType: [
          {
            code: 5,
            name: "协助人审核",
            valkeys: "",
            childs: [],
          },
          {
            code: 1,
            name: "责任部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 2,
            name: "协助人部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 3,
            name: "指定人",
            valkeys: "assistDesignatedPersonnel",
            optionsType: "emplist",
            limit1: 1,
            childs: [],
          },
          {
            code: 4,
            name: "指定部门主管",
            valkeys: "assistDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
        //工单延时审核
        delayedAuditType: [
          {
            code: 1,
            name: "责任部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 2,
            name: "报单人",
            valkeys: "",
            childs: [],
          },
          {
            code: 3,
            name: "指定人",
            valkeys: "delayedDesignatedPersonnel",
            optionsType: "emplist",
            limit1: 1,
            childs: [],
          },
          {
            code: 4,
            name: "指定部门主管",
            valkeys: "delayedDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
        //工单物料审核
        materielAuditType: [
          {
            code: 1,
            name: "指定人",
            valkeys: "materielDesignatedPersonnel",
            optionsType: "emplist",
            limit1: 1,
            childs: [],
          },
          {
            code: 2,
            name: "指定部门主管",
            valkeys: "materielDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
        //工单完成审核
        completeAuditType: [
          {
            code: 1,
            name: "责任部门主管",
            valkeys: "",
            childs: [],
          },
          {
            code: 2,
            name: "报单人",
            valkeys: "",
            childs: [],
          },
          {
            code: 3,
            name: "指定人",
            valkeys: "completeApproveUserList",
            optionsType: "emplist",
            childs: [],
          },
          {
            code: 4,
            name: "指定部门主管",
            valkeys: "completeDesignatedDept",
            optionsType: "depart",
            limit1: 1,
            childs: [],
          },
        ],
      },
      validator: {
        configName: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        defDeptId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        serviceType: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        //					configName: [{required: true,validator: this.checkExists,trigger: 'blur'}],
      },
      validator2: {
        name: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.initNowHtml();
  },
  mounted() {},
  methods: {
    nextStep() {
      this.$refs.htmlFrom.validate((res) => {
        if (!res) {
          this.$message({
            message: "请检查输入是否正确",
            type: "warning",
          });
          return;
        }
        this.nowSteps++;
      });
    },
    setStepsVal() {
      this.nowSteps = 1;
    },
    initNowHtml() {
      this.nowSteps = 1;
      this.initServiceArrs(); //获取服务类型数据
      this.initApplyArrs(); //获取申请类型数据
      this.initDepartment(this.proData.companyId); //获取部门数据
      this.initEmpList(this.proData.companyId); //获取部门主管人员数据
      this.initStatusArrDatas();
      this.initConfigDetails(); //获取配置信息
      this.resetFormItVals();
      this.$nextTick(() => {
        this.$refs.htmlFrom.resetFields();
        this.$refs.configFrom.scrollTop = 0;
      });
    },
    resetFormItVals() {
      //重置表单的值  父组件有调用
      for (let item of this.formlist) {
        //初始化赋值我的表单数据
        this.$set(this.pageParams, item.keys, item.value);
      }
      for (let item of this.formlist2) {
        //初始化赋值我的表单数据
        this.$set(this.examineFrom, item.keys, item.value);
        if (item.auditkey)
          this.$set(this.examineFrom, item.auditkey, item.auditVal);
      }
    },
    hideTransferPersonAudit(val, row) {
      //根据选择的值 隐藏接收人是否审核
      let hideKey = "isTransferPersonAudit",
        isHide = false;
      if (val == 5) {
        //值为5 代表选中的是协助人审核 要将接收人是否审核 设为开启状态
        isHide = true;
        this.examineFrom[hideKey] = "Y";
      } else {
        isHide = false;
      }

      for (let item of this.formlist2) {
        if (item.keys == hideKey) {
          item.isHide = isHide;
          if (this.examineFrom.isTransferAudit == "N") {
            item.isHide = true;
          }
          break;
        }
      }
    },
    hideAssistPersonAudit(val, row) {
      //根据选择的值 隐藏协助人是否审核
      let hideKey = "isAssistPersonAudit",
        isHide = false;
      if (val == 5) {
        //值为5 代表选中的是协助人审核 要将协助人是否审核 设为开启状态
        isHide = true;
        this.examineFrom[hideKey] = "Y";
      } else {
        isHide = false;
      }
      for (let item of this.formlist2) {
        if (item.keys == hideKey) {
          item.isHide = isHide;
          if (this.examineFrom.isAssistAudit == "N") {
            item.isHide = true;
          }

          break;
        }
      }
    },
    openAutoOrder(val, row) {
      //监听报单审核是否变化选中值触发事件
      if (!row.relation) return;
      row.relation.map((rit) => {
        for (let i = 0; i < this.formlist2.length; i++) {
          let item = this.formlist2[i];
          if (item.keys == rit) {
            item.isHide = val == "Y" ? false : true;
            if (rit === "isTransferPersonAudit") {
              //当用户操作 选了工单转派审核为接收人审核时 再次关闭  然后再次打开话  接收人是否审核不应该显示
              if (this.examineFrom.transferAuditType == 5) {
                item.isHide = true;
              }
            }
            if (rit === "isAssistPersonAudit") {
              //当用户操作 选了工单协助审核为接收人审核时 再次关闭  然后再次打开话  协助人是否审核不应该显示
              if (this.examineFrom.assistAuditType == 5) {
                item.isHide = true;
              }
            }

            //							if(rit==='isAssistAudit'){
            //								console.log('---协助是否审核----',item.keys)
            //							}
            break;
          }
        }
      });
    },
    async initServiceArrs() {
      //获取服务类型
      //				if(this.servTypeDatas.length>0){
      //					this.servTypeArrs=this.servTypeDatas;//服务类型数据
      //					this.common.insertOptions(this.formlist,'serviceType',this.servTypeArrs);//添加选项数据到数据类型
      //					return;
      //				}
      let res = await this.ApiHttp("/lookup/lookup/item/find", {
        classifyCode: "ORDER_SERVICE_TYPE",
        isloading: false,
      });
      if (res) {
        res.lookupItemList.map((it) => {
          it.name = it.itemName;
          it.code = it.itemCode;
        });
        this.servTypeArrs = res.lookupItemList; //服务类型数据
        this.common.insertOptions(
          this.formlist,
          "serviceType",
          res.lookupItemList
        ); //添加选项数据到数据类型
      }
    },
    async initApplyArrs() {
      //获取申请类型
      let params = {
        projectId: this.proData.projectId,
        isloading: false,
      };
      if (!params.projectId) return;
      let res = await this.ApiHttp(
        "/order/tenant/orderConfig/getApplyTypeList",
        params
      );
      if (res) {
        res.map((it) => {
          it.name = it.applyTypeName;
          it.code = it.applyTypeId;
        });
        this.applyOptions = res; //服务类型数据
        this.common.insertOptions(
          this.formlist,
          "applyTypeIdList",
          this.applyOptions
        ); //添加选项数据到数据类型
      }
    },
    async initEmpList(companyId) {
      if (!companyId) return;
      //获取部门主管人员数据
      let res = await this.ApiHttp("/organization/tenant/employee/empList", {
        isloading: false,
        companyId: companyId,
      });
      if (res) {
        res.map((it) => {
          it.name = it.userName;
          it.code = it.userId;
        });
        this.empListArrs = res; //获取人员数据
        this.insertCascaderArr("emplist", res); //用于指定人 联级选择项
      }
    },
    async initDepartment(companyId) {
      if (!companyId) return;
      //获取部门数据
      let res = await this.ApiHttp("/organization/tenant/dept/list", {
        isloading: false,
        companyId: companyId,
      });
      if (res && res.length > 0) {
        res.map((it) => {
          it.name = it.deptName;
          it.code = it.deptId;
          if (!it.deptHeadId) it.disabled = true;
        });
        this.departArrs = res; //部门数据
        this.common.insertOptions(this.formlist, "defDeptId", res);
        this.common.insertOptions(this.formlist, "impDeptArr", res);
        this.common.insertOptions(this.formlist, "assistDeptArr", res);
        this.insertCascaderArr("depart", res); //用于责任部门主管 联级选择项
      }
    },
    insertCascaderArr(type, arr) {
      //根据id和类型  插入审核人联级选数据
      if (!type || !arr) return;

      for (let keys in this.auditCheckOptions) {
        this.auditCheckOptions[keys].map((item) => {
          let mapArr = this.auditCheckOptions[keys];
          for (let item of mapArr) {
            if (type == item.optionsType) {
              item.childs = arr;
              break;
            }
          }
          this.common.insertOptions(this.formlist2, keys, mapArr);
        });
      }
    },
    async initConfigDetails() {
      ///获取配置信息
      if (!this.editId) return;
      let res = await this.ApiHttp(
        "/order/tenant/orderConfig/getConfigDetails?orderConfigId=" +
          this.editId
      );
      if (res) {
        //					console.log('获取配置信息',res)
        //处理 成前端回显数据 工单责任部门 impDeptArr和 工单协助部门  assistDeptArr
        res.impDeptArr = [];
        res.applyTypeIdList = [];
        if (res.applyTypeList)
          res.applyTypeList.map((c) => res.applyTypeIdList.push(c.applyTypeId));
        res.assistDeptArr = [];
        res.impDeptList.map((it) => res.impDeptArr.push(it.deptId));
        res.assistDeptList.map((it) => res.assistDeptArr.push(it.deptId));
        this.statusArr.map((it) => {
          //将处理 回显工时设置 的数据
          for (let its of res.configSonList) {
            if (its.orderType == 1) {
              //1 判断状态类型是否为1
              if (it.val == its.orderLevel) {
                //2 匹配对应的orderLevel
                //									console.log('111',it.val,its.orderLevel,it.val==its.orderLevel)
                if (!res.impWorkingHours)
                  res.impWorkingHours = its.impWorkingHours; //赋值
                it.datas = { ...its };
                break;
              }
            }
          }
        });
        //ych-2022-1020
        for (let keys in this.examineFrom) {
          if (res[keys]) this.examineFrom[keys] = res[keys];
        }
        //					console.log('statusArr',JSON.parse(JSON.stringify(this.statusArr)));
        this.formlist2.map((row) => {
          //因审核配置项 会根据状态是否显示页面元素 故通过值触发
          let val = res[row.keys];
          if (row.changMethod) {
            this.openAutoOrder(val, row);
            if (row.keys == "transferAuditType") {
              this.hideTransferPersonAudit(val, row);
            }
            if (row.keys == "assistAuditType") {
              this.hideAssistPersonAudit(val, row);
            }
          }
          if (row.auditVal) {
            //通过返回的值 先比对row中keys 去auditCheckOptions中找出对应的数组的字段valkeys 即是传递过去处理的逆逻辑
            if (!this.auditCheckOptions[row.keys]) return;
            let findData = this.auditCheckOptions[row.keys].filter(
              (el) => el.code == val
            );
            if (findData && findData.length > 0) {
              findData = findData[0];
            }
            if (findData.valkeys) {
              if (res[findData.valkeys] !== -1) {
                //因为提交的时候   前端按后台需要 强制给了-1值  回显需要特殊处理
                if (findData.limit1) {
                  //因单选的时候 前后端交互的数据是number|string 所以要重新组成Arr 前端回显
                  row.auditVal = [res[findData.valkeys]];
                } else {
                  row.auditVal = res[findData.valkeys];
                }
              }
            }
          }
        });
        res.oTypeArrs = []; //工单类型数据  前后端不一致  传递与接收互转
        res.orderTypeList.map((it) => res.oTypeArrs.push(it.orderTypeName));

        this.pageParams = { ...this.pageParams, ...res };
        //					console.log(99999, JSON.parse(JSON.stringify(this.pageParams)))
      }
    },
    initStatusArrDatas() {
      //为订单报警状态详细赋值
      this.statusArr.map((it) => {
        it.datas = {
          orderType: 1,
          orderLevel: it.val,
          unclaimedOvertime: 0, //待领取的工单超过？次分钟，视为一次超时。
          firstExecutedOvertime: 0, //待执行的工单超过？次
          secondaryExecutedOvertime: 0, // 之后每过?次分钟，视为一次超时。
          completedOvertime: "0", //执行中的工单截止要求完成时间还未完成提交视为一次超时，之后每过？分钟，视为一次超时。
          timeReportDuty: 0, //第？次超时，通知岗位负责人。
          timeReportDept: 0, //第？ 次超时，通知部门负责人。
          timeReportSuperiorDept: 0, // 次超时，通知上级部门负责人。
          timeReportSuperiorMax: 0, // 最多越?级上报。
        };
      });
    },
    async onSubmit() {
      let params = {
        ...this.pageParams,
        ...this.examineFrom,
        isAddDevice: "Y", //是否允许添加设备 当前需求默认为Y
        isApproveSequence: "N", //是否审核是否有序 当前需求没有开关操作
        projectId: this.proData.projectId,
        impDeptList: [], //对应前端impDeptArr字段，后端需要格式为[{deptId:***}]
        assistDeptList: [], //对应前端assistDeptArr字段，后端需要格式为[{deptId:***}]
        configSonList: [], //工时设置数据
      };

      //处理默认责任部门与责任部门的问题，如果默认责任部门不在责任部门里面 需要添加进去
      if (params.impDeptArr.indexOf(params.defDeptId) < 0) {
        params.impDeptList.push({ deptId: params.defDeptId });
      }
      this.pageParams.impDeptArr.map((it) =>
        params.impDeptList.push({
          deptId: it,
        })
      );

      this.pageParams.assistDeptArr.map((it) =>
        params.assistDeptList.push({
          deptId: it,
        })
      );
      this.statusArr.map((it) => {
        it.datas.impWorkingHours = params.impWorkingHours;
        params.configSonList.push(it.datas);
      });
      let errorTips; //是否允许提交，判断如果选了指定人或者部门的话  对应下拉框没选的话 给予错误提示 ;
      for (let keys in this.auditCheckOptions) {
        if (params[keys]) {
          //因 后台接收的每个类型字段不一致，处理报单审核 人传递的字段，并且选中又分别为单选与多选
          let itAudit;
          this.auditCheckOptions[keys].map((it) => {
            //1. 从多个审核人选项集合中找出keys对应的数据 然后通过中的类别去拿到该数据 例如{code: '2',name: '指定人',valkeys:''*****},
            if (it.code == params[keys]) {
              itAudit = it;
            }
          });
          if (!itAudit) continue;

          //						console.log('params[keys]', keys, params[keys])
          if (itAudit.valkeys) {
            //valkeys 存在时既代表 要将选中的数据 赋值到该keys上 [分string和arr] 传递给后台
            let result = "";

            let fItem = this.formlist2.filter((el) => el.keys === keys); //从formlist 中查找出该条的具体信息 拿到通过绑定在auditVal的值
            if (fItem.length < 1) return;
            let findVal = fItem[0].auditVal;

            let findRelation; //查出是哪个关联的。比如延时是否审核Y时候 关联工单延时审核 当选择指定人或者部门主管时   该auditVal必须要有值
            for (let its of this.formlist2) {
              if (its.relation && its.relation.indexOf(keys) > -1) {
                findRelation = its;
                break;
              }
            }
            if ((params[findRelation.keys] == "Y") & (findVal.length < 1)) {
              //没有选择指定人的时候 不允许提交
              errorTips = fItem[0].name;
              break;
            }

            if (itAudit.limit1) {
              //limit1 存在时候 即代表只能选择一个
              result = findVal[0];
            } else {
              result = findVal;
            }
            params[itAudit.valkeys] = result;
          }
        }
      }

      if (errorTips) {
        this.$message({
          type: "warning",
          message: errorTips + "请选择对应的人或部门主管",
        });
        return;
      }
      //				if(params.materielAuditType==1){
      //					//因后台接口 某数据库必要字段，前端强制传值
      //					params.materielDesignatedDept=-1;
      //				}else{
      //					params.materielDesignatedPersonnel=-1;
      //				}
      if (params.isMaterielAudit == "N") {
        params.materielDesignatedDept = -1;
        params.materielDesignatedPersonnel = -1;
      }
      if (!params.materielDesignatedDept) {
        params.materielDesignatedDept = -1;
      }
      if (!params.materielDesignatedPersonnel)
        params.materielDesignatedPersonnel = -1;
      //提交时进行校验-保留旧版本逻辑
      if (params.assistAuditType == 5) {
        //当转派审核为接收人时  是否接收人审核设置为否
        params.isAssistPersonAudit = "N";
      }
      if (params.transferAuditType == 5) {
        //当协助审核为协助人时  是否协助人审核设置为否
        params.isTransferPersonAudit = "N";
      }
      //把前端的数据转成后端需要的
      params.orderTypeList = [];
      params.oTypeArrs.map((it) =>
        params.orderTypeList.push({
          orderTypeName: it,
        })
      );
      let urlType = "addConfig"; //默认新增
      let sendType = "post";
      if (this.editId) {
        //有id 时 修改
        urlType = "updateConfig";
        params.orderConfigId = this.editId;
        sendType = "put";
      }

      let res = await this.ApiHttp(
        "/order/tenant/orderConfig/" + urlType,
        params,
        sendType
      ).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.nowSteps = 1;
        if (!this.editId) {
          this.resetFormItVals();
        }
        this.$emit("sendOver");
      });
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.AddHtml {
  padding: 20px 10px;
  .configFrom {
    height: calc(100vh - 272px);
    overflow-y: auto;
    .f_btns {
      padding-left: 108px;
    }
    .pl120px {
      padding-left: 120px;
      margin-top: 28px;
      /*	padding-top: 28px;*/
    }
    /*.Y-myFrom,.f_btns{
				max-width: 800px;
    		margin: 0 auto;
			}*/
  }
  .w108 {
    width: 108px;
  }
  .ySteps {
    padding-bottom: 40px;
    text-align: center;
    margin: 0 auto;
    .it_step {
      color: #dbdfea;
      position: relative;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      .round {
        position: relative;
        top: -1px;
        margin-right: 10px;
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: white;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        background: #dbdfea;
      }
      .line {
        display: inline-block;
        width: 80px;
        height: 2px;
        background: #dbdfea;
        vertical-align: middle;
        position: relative;
        margin: 0 10px;
        top: -2px;
      }
    }
    .it_step.isPass {
      color: #409eff;
      .round,
      .line {
        background: #409eff;
      }
    }
  }
  .alarmBox {
    margin: 15px 0;
    position: relative;
    .txGray {
      font-size: 12px;
      color: #999;
      line-height: 40px;
      padding: 0 20px;
      position: absolute;
      top: 0;
      left: 210px;
    }
    .itConfigs {
      ul li {
        line-height: 40px;
        margin: 15px 0;
      }
      .w108 {
        margin: 0 12px;
      }
    }
  }
  .w130 {
    width: 130px;
  }
  .txCenter {
    margin-top: 12px;
  }
}
</style>
<style lang="scss">
	.max260 .el-form-item__content {
		width: 50% !important;
		max-width: 260px;
	}
	.desTxare{
		.el-form-item__label{
			line-height: 24px !important;
		}
	}
	
	.no_border {}
	.torow1.el-select{
		width: 642px !important;
	}
</style>