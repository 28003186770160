var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "AddHtml" }, [
    _c(
      "div",
      { staticClass: "ySteps" },
      _vm._l(_vm.stepArrs, function (it, dex) {
        return _c(
          "div",
          {
            key: dex,
            staticClass: "it_step",
            class: { isPass: _vm.nowSteps >= it.val },
          },
          [
            dex > 0 ? _c("div", { staticClass: "line" }) : _vm._e(),
            _c("div", { staticClass: "round" }, [_vm._v(_vm._s(it.val))]),
            _vm._v("\n      " + _vm._s(it.name) + "\n    "),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { ref: "configFrom", staticClass: "configFrom" },
      [
        _vm.nowSteps < 2
          ? _c("TForm", {
              ref: "htmlFrom",
              attrs: {
                model: _vm.pageParams,
                rules: _vm.validator,
                formlist: _vm.formlist,
                "label-width": "108px",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "oTypeArrs",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c("tag-bind", {
                              attrs: {
                                tagValueList:
                                  _vm.pageParams[scope.current.keys],
                                tagType: "",
                                notAuto: true,
                                limit: 4,
                                maxlength: 4,
                                addName: "添加类型",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "robOrder",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "w108",
                              attrs: {
                                placeholder: "请输入",
                                oninput:
                                  "value=value?value.replace(/[^0-9]/g,''):0",
                                maxlength: "2",
                              },
                              model: {
                                value: _vm.pageParams[scope.current.keys],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageParams,
                                    scope.current.keys,
                                    $$v
                                  )
                                },
                                expression: "pageParams[scope.current.keys]",
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(scope.current.tips))]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "workHours",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v(_vm._s(scope.current.tips))]),
                              _vm._v("  \n            "),
                              _c("el-input", {
                                staticClass: "w108",
                                attrs: {
                                  placeholder: "请输入",
                                  oninput:
                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                  maxlength: "3",
                                },
                                model: {
                                  value: _vm.pageParams[scope.current.keys],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageParams,
                                      scope.current.keys,
                                      $$v
                                    )
                                  },
                                  expression: "pageParams[scope.current.keys]",
                                },
                              }),
                              _vm._v("\n              分钟\n          "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "alarmBox" },
                            [
                              _c("div", { staticClass: "txGray" }, [
                                _vm._v("（说明：按工单级别）"),
                              ]),
                              _c(
                                "el-tabs",
                                {
                                  attrs: { type: "card" },
                                  model: {
                                    value: _vm.pageParams.statusType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageParams,
                                        "statusType",
                                        $$v
                                      )
                                    },
                                    expression: "pageParams.statusType",
                                  },
                                },
                                _vm._l(_vm.statusArr, function (it, dex) {
                                  return _c(
                                    "el-tab-pane",
                                    {
                                      key: dex,
                                      staticClass: "no_border",
                                      attrs: { label: it.name, name: it.val },
                                    },
                                    [
                                      _c("div", { staticClass: "itConfigs" }, [
                                        _c("ul", [
                                          _c(
                                            "li",
                                            [
                                              _vm._v(
                                                "\n                      待领取的工单超过\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas.unclaimedOvertime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "unclaimedOvertime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.unclaimedOvertime",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      分钟未被领取，则视为超时。\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "li",
                                            [
                                              _vm._v(
                                                "\n                      待执行的工单超过\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas
                                                      .firstExecutedOvertime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "firstExecutedOvertime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.firstExecutedOvertime",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      分钟，还未开始执行视为一次超时，之后每过\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas
                                                      .secondaryExecutedOvertime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "secondaryExecutedOvertime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.secondaryExecutedOvertime",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      分钟，视为一次超时。\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "li",
                                            [
                                              _vm._v(
                                                "\n                      执行中的工单截止要求完成时间还未完成提交视为一次超时，之后每过\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas.completedOvertime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "completedOvertime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.completedOvertime",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      分钟，视为一次超时。\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "li",
                                            [
                                              _vm._v(
                                                "\n                      第\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas.timeReportDuty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "timeReportDuty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.timeReportDuty",
                                                },
                                              }),
                                              _vm._v(
                                                "次超时，通知岗位负责人。\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "li",
                                            [
                                              _vm._v(
                                                "\n                      第\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas.timeReportDept,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "timeReportDept",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.timeReportDept",
                                                },
                                              }),
                                              _vm._v(
                                                "次超时，通知部门负责人。\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "li",
                                            [
                                              _vm._v(
                                                "\n                      第\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas
                                                      .timeReportSuperiorDept,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "timeReportSuperiorDept",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.timeReportSuperiorDept",
                                                },
                                              }),
                                              _vm._v(
                                                "次超时，通知上级部门负责人， 最多越\n                      "
                                              ),
                                              _c("el-input", {
                                                staticClass: "w108",
                                                attrs: {
                                                  oninput:
                                                    "value=value?value.replace(/[^0-9]/g,''):0",
                                                  maxlength: "3",
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    it.datas
                                                      .timeReportSuperiorMax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it.datas,
                                                      "timeReportSuperiorMax",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.datas.timeReportSuperiorMax",
                                                },
                                              }),
                                              _vm._v(
                                                "级上报。\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                360251702
              ),
            })
          : _c("TForm", {
              ref: "htmlFrom",
              attrs: {
                model: _vm.examineFrom,
                rules: _vm.validator2,
                formlist: _vm.formlist2,
                "label-width": "120px",
              },
            }),
        _vm.nowSteps < 2
          ? _c(
              "div",
              { staticClass: "f_btns" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w130",
                    attrs: { type: "primary" },
                    on: { click: _vm.nextStep },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "f_btns pl120px" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w130",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.nowSteps--
                      },
                    },
                  },
                  [_vm._v("上一步")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "w130",
                    attrs: { type: "primary" },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }